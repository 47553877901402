export default class FoldBlock{
	constructor(){
		this.foldblocks = [];
		this.toggle = e => 
		window.addEventListener("beforeTransition", () => this.leave(), false);
		window.addEventListener("afterTransition", () => this.initialize(), false);
		this.initialize();
	}

	leave(){
		this.foldblocks = [];
	}

	initialize(){
		this.foldblocks = document.querySelectorAll('.foldblock');
		if(this.foldblocks.length){
			this.foldblocks.forEach((elm) => {
				elm.addEventListener('click', (e) => {
					this.toggleBlock(elm);
				}, false);
			});
		}
	}

	toggleBlock(block){
		const body = block.querySelector(".foldblock__body");
		const content = block.querySelector(".foldblock__content");
		if(!block.classList.contains("open")){
			block.classList.add("open");
			body.style.height = content.offsetHeight+"px";
		}else{
			block.classList.remove("open");
			body.style.height = 0;
		}
	}
}