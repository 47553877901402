export default class ScrollFx{
	constructor(){
		this.stop = true;
		this.threshold = 0;

		window.addEventListener("scroll", () => {
			this.onScrollHandler();
		}, false);
		window.addEventListener("beforeTransition", () => {
			this.stopScrollHandler();
		}, false);
		window.addEventListener("staticReload", () => {
			this.stopScrollHandler();
			this.resetAppearance();
		}, false);
		window.addEventListener("transitionComplete", () => {
			this.initialize();
			this.startScrollHandler();
			this.onScrollHandler();
		}, false);
	}

	initialize(){
		this.fxBlocks = [];
		document.querySelectorAll('.scrollFx').forEach((block) => {
			this.fxBlocks.push({
				dom: block,
				appear: false
			});
		});
	}
	onScrollHandler(){
		if(this.stop) return false;
		this.fxBlocks.forEach((block) => {
			if(block.appear) return
			const bound = block.dom.getBoundingClientRect();
			if(bound.top <= window.innerHeight - this.threshold){
				block.dom.classList.add('appear');
				block.appear = true;
			}
		});
	}
	stopScrollHandler(){
		this.stop = true;
	}
	startScrollHandler(){
		this.stop = false;
	}
	resetAppearance(){
		console.log("resetAppearance");
		this.fxBlocks.forEach((block) => {
			if(block.appear){
				block.dom.classList.remove('appear');
				block.appear = false
			}
		});
	}
}