export default class Index{
	constructor(){
		this.hero = null;
		this.initialize(null);
		
		window.addEventListener("afterTransition", ()=>{
			this.initialize(null);
		}, false);

		window.addEventListener("resize", ()=>{
			this.indexHeroResizeHandler();
		}, false);
	}

	initialize(dom){
		this.hero = null;
		if(document.body.classList.contains("index")){
			const h = document.querySelector('.header.index-hero');
			if(h) this.hero = h;
			this.indexHeroResizeHandler();
		}
	}

	indexHeroResizeHandler(){
		if(!this.hero) return false;
		this.hero.style.height = (window.innerHeight - 120)+"px";
	}

}