import anime from 'animejs/lib/anime.es.js';

export default class Backtop{
	constructor(){
		this.scrollElement = window.document.scrollingElement || window.document.body || window.document.documentElement;
		this.button = document.querySelector('.footer__backtop');
		if(this.button) this.initialize();
	}

	initialize(){
		this.button.addEventListener('click', (e) => {
			anime({
				targets: this.scrollElement,
				scrollTop: 0,
				duration: 750,
				easing: 'easeOutExpo'
			});
			e.preventDefault();
			return false;
		}, false);
	}
}