export default class ContactForm{
	constructor(){
		this.initialize(null);
	}

	initialize(dom){
		let targetDom = document;
		if(dom){
			if(dom instanceof Element) targetDom = dom;
		}
		if(targetDom.querySelector('#contactform')){
			const form = targetDom.querySelector('#contactform');
			this.initFileUpload(form);
		}
		/*
		if(targetDom.querySelectorAll('.contact__inquiry__form').length){
			targetDom.querySelectorAll('.contact__inquiry__form__select').forEach((elm) => {
				this.pulldownChange(elm);
			});
			targetDom.querySelectorAll('form.contact__inquiry__form').forEach((elm) => {
				this.initSend(elm);
			});
		}
		*/
	}

	initFileUpload(form){
		const fileinput = form.querySelectorAll(".contact__form__attachment__field input[type='file']");
		fileinput.forEach((elm) => {
			elm.addEventListener('change', (e) => {
				console.log(elm.files[0].name);
				console.log(elm.files[0].size);
				console.log(elm.files[0].type);
			}, false);
		});
		
	}
}