export default class Menu{
	constructor(){
		this.isToggle = false;
		this.menu = document.querySelector('.menu');
		this.container = document.querySelector('.menu__container');
		this.innercontainer = document.querySelector('.menu__innercontainer');
		this.content = document.querySelector('.menu__content');
		this.toggle = document.querySelector('.menu__toggle');
		this.navItems = document.querySelectorAll('.menu__nav a');

		this.isStick = false;
		this.isShow = false;
		this.noDuration = false;
		this.lastScrollTop = 0;

		this.initialize();
	}

	initialize(){
		this.toggle.addEventListener('click', (e) => {
			this.toggleMenu();
			e.preventDefault();
			return false;
		}, false);

		window.addEventListener('resize', ()=>{ this.menuResizeHandler() }, false);
		this.menuResizeHandler();

		window.addEventListener('scroll', ()=>{ this.menuScrollHandler() }, false);
		this.menuScrollHandler();

		window.addEventListener('afterTransition', ()=>{
			if(this.isToggle) this.toggleMenu();
		}, false);
		window.addEventListener('staticReload', ()=>{
			if(this.isToggle) this.toggleMenu();
		}, false);

		this.content.style.height = window.innerHeight+'px';
		this.content.style.width = window.innerWidth+'px';
		this.innercontainer.style.height = window.innerHeight+'px';
		this.innercontainer.style.width = window.innerWidth+'px';
		this.container.style.height = window.innerHeight+'px';
		this.container.style.width = '0px';
	}

	toggleMenu(){
		if(this.isToggle){
			this.menu.classList.remove('open');
			this.container.style.width = '0px';
			this.isToggle = false;
		}else{
			this.menu.classList.add('init');
			setTimeout(()=>{
				this.menu.classList.remove('init');
				this.menu.classList.add('open');
				this.isToggle = true;
				this.menuResizeHandler();
			},10)
		}
	}

	menuResizeHandler(){
		if(!this.isToggle) return false;

		this.content.style.height = window.innerHeight+'px';
		this.content.style.width = window.innerWidth+'px';
		this.innercontainer.style.height = window.innerHeight+'px';
		this.innercontainer.style.width = window.innerWidth+'px';
		this.container.style.height = window.innerHeight+'px';
		this.container.style.width = window.innerWidth+'px';
	}

	menuScrollHandler(){
		const threshold = 120;
		const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
		if(!this.isToggle){
			if(this.noDuration){
				this.noDuration = false;
				this.menu.classList.remove("noDuration");
			}
			if(scrollTop > threshold){
				if(!this.isStick){
					this.isStick = true;
					this.noDuration = true;
					this.menu.classList.add("stick");
					this.menu.classList.add("noDuration");
				}
				if(scrollTop < this.lastScrollTop){
					if(!this.isShow){
						this.isShow = true;
						this.menu.classList.add("show");
					}
				}else if(scrollTop > this.lastScrollTop){
					if(this.isShow){
						this.isShow = false;
						this.menu.classList.remove("show");
					}
				}
			}else if(scrollTop <= 0){
				this.isStick = false;
				this.isShow = false;
				this.menu.classList.remove("stick");
				this.menu.classList.remove("show");
			}
		}
		this.lastScrollTop = scrollTop;
	}
}