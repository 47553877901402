import anime from 'animejs/lib/anime.es.js';

export default class EmbedMap{
	constructor(){
		this.initialize();
		window.addEventListener("afterTransition", () => this.initialize(), false);
	}

	initialize(){
		if(!window.gmapApiIsReady) return;
		this.embedmap = null;
		this.embedmap = document.querySelector('#embedmap');
		if(this.embedmap) this.embed();
	}

	embed(){
		const lat = 35.516860;
		const lng = 134.820075;
		const mapCenter = new google.maps.LatLng(lat,lng);
		const makerCenter = new google.maps.LatLng(lat,lng);
		const mapOptions = {
			center: mapCenter,
			zoom: 13,
			mapTypeId: google.maps.MapTypeId.ROADMAP,
			disableDefaultUI: true,
			mapTypeControl: false,
			zoomControl: true,
			scrollwheel: false,
			draggable: true,
			styles: [{"stylers": [
				{"saturation": -100}
			]}]
		};
		const map = new google.maps.Map(this.embedmap, mapOptions);
		const markerimg = '/images/common/mapmark.png';
		if(window.innerWidth >= 768){
			const maker = new google.maps.Marker({
				position: makerCenter,
				map: map,
				icon: new google.maps.MarkerImage(markerimg,
					new google.maps.Size(320, 208),		//オリジナルサイズ
					new google.maps.Point(0,0),			//原点
					new google.maps.Point(80, 104),		//先端の位置（縮小後のサイズから計算）
					new google.maps.Size(160, 104)),		//縮小後のサイズ
			});
		}else{
			const maker = new google.maps.Marker({
				position: makerCenter,
				map: map,
				icon: new google.maps.MarkerImage(markerimg,
					new google.maps.Size(320, 208),		//オリジナルサイズ
					new google.maps.Point(0,0),			//原点
					new google.maps.Point(60, 78),		//先端の位置（縮小後のサイズから計算）
					new google.maps.Size(120, 78)),		//縮小後のサイズ
			});
		}
	}
}