import barba from '@barba/core';
import imagesLoaded from 'imagesloaded';

export default class Pjax{
	constructor(){
		this.transitionMask = document.querySelector('.transitionmask');
		this.transitionMaskInner = document.querySelector('.transitionmask__inner');
		this.transitionMaskProgressBar = document.querySelector('.transitionmask__progress>div');
		this.beforeTransitionEvent = new Event('beforeTransition');
		this.afterTransitionEvent = new Event('afterTransition');
		this.transitionCompleteEvent = new Event('transitionComplete');
		this.staticReloadEvent = new Event('staticReload');
		this.isMaskOpen = false;

		this.initialize();
	}

	initialize(){
		barba.init({
			prevent: ({ el, event, href }) => {
				if( href.match(/^#/) ) return true;
			},
			transitions: [
				{
					before: async () => {
						window.dispatchEvent(this.beforeTransitionEvent);
						this.transitionMask.style.display = "block";
						this.transitionMaskProgressBar.style.transform = "scale(0,1)";
						this.transitionMaskProgressBar.classList.add("linear");
						await new Promise((resolve) => {
							return setTimeout(resolve, 50);
						});
					},
					leave: async () => {
						document.body.classList.add('transitioning');
						this.isMaskOpen = true;
						this.transitionMaskResizeHandler();
						this.transitionMaskProgressBar.style.transform = "scale(0.3,1)";
						await new Promise((resolve) => {
							return setTimeout(resolve, 500);
						});
					},
					beforeEnter: (data) => {
						document.body.removeAttribute('class');
						document.body.classList.add(data.next.namespace);
						document.body.classList.add('transitioning');
						if(window.isTouch) document.body.classList.add('touch');
						if(window.isStrokeSupport) document.body.classList.add('outlineStrokeSupport');
					},
					afterEnter: () => {
						window.scrollTo(0, 0);
						this.loadPageContents();
						// gtag('config', 'UA-GTAGID', {'page_path' : location.pathname});
					},
					after: () => {
						window.dispatchEvent(this.afterTransitionEvent);
					}
				}
			]
		});

		document.querySelectorAll('a[href]').forEach((elm) => {
			elm.addEventListener('click', (e) => {
				if(e.currentTarget.href === window.location.href) {
					e.preventDefault();
					e.stopPropagation();
					this.staticReload();
				}
			});
		})
		window.addEventListener("resize", e => this.transitionMaskResizeHandler(), false);

		document.body.classList.add('transitioning');
		this.isMaskOpen = true;
		this.transitionMaskResizeHandler();
		this.loadPageContents();
	}

	transitionMaskResizeHandler(){
		if(!this.isMaskOpen) return false;
		this.transitionMask.style.width = window.innerWidth+'px';
		this.transitionMaskInner.style.width = window.innerWidth+'px';
	}

	loadPageContents(){
		let progressCount = 0;
		let progressBarLength = .3;
		const imgloader = imagesLoaded( document.querySelectorAll('*'), { background: true } );
		const totalCount = imgloader.images.length + 2;

		const countup = (flag)=>{
			progressCount++;
			progressBarLength += flag ? 0.14 : 0.42/imgloader.images.length;
			if(progressCount >= totalCount){
				progressBarLength = 1;
				this.openTransition();
			}
			this.transitionMaskProgressBar.style.transform = "scale("+progressBarLength+",1)";
		};

		this.transitionMaskProgressBar.classList.remove("linear");

		countup(true);
		try{
			Typekit.load({ active: () => { countup(true); } });
		}catch(e){
			countup(true);
		}
		
		imgloader.on( 'progress', (instance, image) => {
			countup(false);
		});
	}

	openTransition(){
		setTimeout(()=>{
			this.isMaskOpen = false;
			this.transitionMask.style.width = '0px';
			document.body.classList.remove('transitioning');
			setTimeout(()=>{
				this.transitionMask.style.display = "none";
				window.dispatchEvent(this.transitionCompleteEvent);
			},500);
		}, 500);
	}

	staticReload(){
		this.transitionMask.style.display = "block";
		this.transitionMaskProgressBar.style.transform = "scale(0,1)";
		this.transitionMaskProgressBar.classList.add("linear");
		setTimeout(()=>{
			document.body.classList.add('transitioning');
			this.isMaskOpen = true;
			this.transitionMaskResizeHandler();
			this.transitionMaskProgressBar.style.transform = "scale(0.3,1)";
			setTimeout(()=>{
				window.scrollTo(0, 0);
				window.dispatchEvent(this.staticReloadEvent);
				this.loadPageContents();
			}, 500)
		}, 50);
	}
}