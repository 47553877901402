export default class ProductSlide{
	constructor(){
		window.addEventListener("afterTransition", () => this.initialize(), false);
		this.initialize();
	}

	initialize(){
		if(document.querySelectorAll('.products__article__figs').length){
			document.querySelectorAll('.products__article__figs').forEach((elm) => {
				this.initProductSlide(elm);
			});
		}
	}

	initProductSlide(block){
		const figs = block.querySelectorAll(".products__article__figs__fig");
		const pager = block.querySelector(".products__article__figs__pager");
		const viewport = block.querySelector(".products__article__figs__viewport");
		const container = block.querySelector(".products__article__figs__container");

		let position = 0;
		let firstX = 0;
		let firstY = 0;
		let currentX = 0;
		let currentY = 0;
		let active = null;

		block.setAttribute("data-max", figs.length-1);
		for(let i=0; i<figs.length; i++){
			const p = document.createElement("span");
			p.classList.add("products__article__figs__pager__page");
			pager.appendChild(p);
		}

		viewport.addEventListener("touchstart", (e)=>{
			firstX = e.pageX | e.touches[0].pageX;
			firstY = e.pageY | e.touches[0].pageY;
			active = null;
			container.classList.remove("dragging");
		}, false);
		viewport.addEventListener("touchmove", (e)=>{
			currentX = e.pageX | e.touches[0].pageX;
			currentY = e.pageY | e.touches[0].pageY;
			if(active === null){
				if(Math.abs(firstY - currentY) > Math.abs(firstX - currentX)){
					active = false;
				}else{
					active = true;
					container.classList.add("dragging");
				}
			}
			if(active){
				e.preventDefault();
				container.style.transform = "translate("+(-position*222 + (currentX-firstX)/2)+"px,0px)";
			}
		}, false);
		viewport.addEventListener("touchend", (e)=>{
			if(active && Math.abs(firstX-currentX) > 80){
				if(firstX-currentX < 0){
					position = Math.max(0, position-1);
				}else if(firstX-currentX > 0){
					position = Math.min(figs.length-1, position+1);
				}
				block.setAttribute("data-position", position);
			}
			active = null;
			container.classList.remove("dragging");
			container.removeAttribute("style");
		}, false);
	}
}