import Menu from './modules/_menu.js';
import Index from './modules/_index.js';
import FoldBlock from './modules/_foldblock.js';
import ScrollFx from './modules/_scrollFx.js';
import ProductSlide from './modules/_productslide.js';
import ContactForm from './modules/_contactform.js';
import Backtop from './modules/_backtop.js';
import EmbedMap from './modules/_embedmap.js';
import Pjax from './modules/_pjax.js';

class App {
	constructor() {
		this.menu = new Menu();
		this.index = new Index();
		this.foldblock = new FoldBlock();
		this.scrollFx = new ScrollFx();
		this.productslide = new ProductSlide();
		this.contactform = new ContactForm();
		this.backtop = new Backtop();
		this.embedmap = new EmbedMap();
		this.pjax = new Pjax();
	}
}

let app = null;
document.addEventListener('DOMContentLoaded', () => {
	if( navigator.userAgent.indexOf('iPhone') > 0        ||
		navigator.userAgent.indexOf('iPod') > 0          ||
		navigator.userAgent.indexOf('iPad') > 0          ||
		navigator.userAgent.indexOf('Windows Phone') > 0 ||
		navigator.userAgent.indexOf('BlackBerry') > 0    ||
		navigator.userAgent.indexOf('Android') > 0 ) {
		window.isTouch = true;
		document.body.classList.add('touch');
	}
	if( navigator.userAgent.indexOf('Edge') < 0){
		if( navigator.userAgent.indexOf('iPhone') > 0  || 
			navigator.userAgent.indexOf('iPod') > 0    || 
			navigator.userAgent.indexOf('iPad') > 0    || 
			navigator.userAgent.indexOf('Android') > 0 || 
			navigator.userAgent.indexOf('Safari') > 0  || 
			navigator.userAgent.indexOf('Chrome') > 0  || 
			navigator.userAgent.indexOf('Opera') > 0 ) {
			window.isStrokeSupport = true;
			document.body.classList.add("outlineStrokeSupport");
		}
	}
	app = new App();
}, false);

window.gmapApiReady = () => {
	window.gmapApiIsReady = true;
	if(app){
		app.embedmap.initialize();
	}
}